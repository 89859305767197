import {call, put, takeEvery} from 'redux-saga/effects';
import httpService from '../httpService';
import * as apiUrl from '../apiUrl';
// import { filterParamsData } from '../dummyData.js';
import { logError } from '../../Utils/Utils';

async function fetchParamListDataApiCall(paramsLoad){
    const regParams = paramsLoad ? paramsLoad : ''; 
    console.log('regParams regParams regParams', regParams.regionId);
    try {
        const response = await httpService.getCallMethod(apiUrl.fetchFilterParams+'/'+ regParams.regionId +'/'+ regParams.publish_date); //regParams.publish_date

        const fullUrl = `${apiUrl.fetchFilterParams}/${regParams.regionId}/${regParams.publish_date}`;
        console.log('Filetr Params API :', fullUrl);
        return response.data;
    } catch (error) {
        logError('fetchFilterParams', error);
        throw error; 
    }
}

async function fetchRegionCall(){
    try {
        const response = await httpService.getCallMethod(apiUrl.fetchRegionUrl);
        return response;
    } catch (error) {
            // Log the error to the backend server
            logError('fetchRegionCall', error);
            throw error;
    }
}
function* fetchingRegionDatas(){
    try {
        const region = yield call (()=> fetchRegionCall());
        yield put({type: 'FETCH_REGIONS_SUCCESS', regionDatasFromApi : region})
    } catch (error) {
        yield put ({type: 'FETCH_ADS_BY_POST_FAILED', regionDataErrorFromApi : error})
    }
}


function* fetchinitialParamsData(action){
     try {
        const fetchedParamData = yield call(()=>fetchParamListDataApiCall(action?.payload))
        yield put({type : 'FETCH_INITIAL_PARAMS_DATA_SUCESS', fetchedParamData: fetchedParamData})
     } catch (error) {
        yield put({type: 'FETCH_INITIAL_PARAMS_DATA_FAILED', erorInfetchedParamData: error})
     }
}

function* fetchinitialmetadataSaga(){
    yield takeEvery('FETCH_INITIAL_PARAMS_DATA', fetchinitialParamsData)
}

export function* fetchregionlistSaga(){
    yield takeEvery('FETCH_REGIONS',  fetchingRegionDatas)
}
export default fetchinitialmetadataSaga; 